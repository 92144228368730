import React from 'react';

const ProcessCCC: React.FC = () => {
    return (
        <div data-testid="processNew-section">
            <div className="processNew-container" data-testid="ProcessNew-container">
                <div className="bgsection">
                    <div className="container960 my-5">
                        <h3 className="center mt-5">What you&apos;ll get with a free chat:</h3>
                        {/* bigger-mid screens */}
                        <div className="row d-none d-md-flex" data-testid="card-1">
                            <div className="col-md-4">
                                <h4>Free Analysis</h4>
                                <img className="img-fluid" src="/images/ccc/analysis.png" alt="analysis chart" />
                                <p>Thorough automated analysis examining 5 different areas.</p>
                            </div>
                            <div className="col-md-4">
                                <h4>Free Savings Report</h4>
                                <img className="img-fluid" src="/images/ccc/report.png" alt="report" />
                                <p>Comprehensive report shows specific areas where you can save.</p>
                            </div>
                            <div className="col-md-4">
                                <h4>Recommended Plan</h4>
                                <img className="img-fluid" src="/images/ccc/30-50.png" alt="analysis chart" />
                                <p>Recommended &quot;done-for-you&quot; process to start saving 30-50%. </p>
                            </div>
                        </div>

                        {/* smaller screens */}
                        <div className="row d-md-none mt-3" data-testid="card-2">
                            <div className="col-3">
                                <img className="img-fluid" src="/images/ccc/analysis.png" alt="analysis chart" />
                            </div>
                            <div className="col-9">
                                <h4>Free Analysis</h4>
                                <p>Thorough automated analysis examining 5 different areas.</p>
                            </div>
                        </div>

                        <div className="row d-md-none mt-3" data-testid="card-3">
                            <div className="col-3">
                                <img className="img-fluid" src="/images/ccc/report.png" alt="report" />
                            </div>
                            <div className="col-9">
                                <h4>Free Savings Report</h4>
                                <p>Comprehensive report shows specific areas where you can save.</p>
                            </div>
                        </div>

                        <div className="row d-md-none mt-3" data-testid="card-4">
                            <div className="col-3">
                                <img className="img-fluid" src="/images/ccc/30-50.png" alt="analysis chart" />
                            </div>
                            <div className="col-9">
                                <h4>Recommended Plan</h4>
                                <p>Recommended &quot;done-for-you&quot; process to start saving 30-50%. </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProcessCCC;
