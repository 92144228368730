import * as React from 'react';
import { Helmet } from 'react-helmet';
import CCCContact from '../compontents/CCCContact/CCCContact';
import Layout from '../compontents/Layout/Layout';
import ProcessCCC from '../compontents/ProcessCCC/ProcessCCC';

type CCCContactPageProps = {
    location: any;
};

const CCCContactPage: React.FC<CCCContactPageProps> = ({ location }) => {
    return (
        <div data-testid="contact-us-page">
            <Layout location={location}>
                <Helmet>
                    <title>Contact Us for a free FinOps chat about Cloud Cost Control</title>
                </Helmet>

                <div>
                    <CCCContact />
                </div>

                <div id="process">
                    <ProcessCCC />
                </div>
            </Layout>
        </div>
    );
};

export default CCCContactPage;
